import React from "react"
import { Link } from "gatsby"
// import blog3 from "../../assets/images/blog/blog-03-370x230.webp"
import blog2 from "../../assets/images/blog/blog-02-370x230.webp"
import "../../assets/css/style.css"
import "../../assets/css/custom.css"
// import "../../assets/js/vendor/bootstrapp.min.js"
import "../../assets/css/vendor/vendor.min.css"
import "../../assets/css/plugins/plugins.min.css"
// import blog from "../../assets/images/timeline/blog-07-500x350.webp"

import teamwork from '../../assets/images/banners/icon-pack/team.png'
import customer from '../../assets/images/banners/icon-pack/support.png'
import interactive from '../../assets/images/banners/icon-pack/interactivity.png'
import infrastructure from '../../assets/images/banners/icon-pack/infrastructure.png'
import management from '../../assets/images/banners/icon-pack/management.png'

const WhyChooseUs = () => {
  return (
    <div id="main-wrapper">
      {/* <div className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_box text-center">
                <h1 className="breadcrumb-title">Why Choose Us</h1>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="why-us-banner-wrap banner-space why-us-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="why-us-banner-content text-center">
                <h1 className="mb-15 text-white">Why Choose Us</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="site-wrapper-reveal">
        {/* <!--===========  feature-large-images-wrapper  Start =============--> */}
        <div className="feature-large-images-wrapper section-space--ptb_100 bg-gray">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* <!-- section-title-wrap Start --> */}
                <div className="section-title-wrap text-center section-space--mb_30">
                  <h2 className="heading mb-20">
                    Preparing for your success, we provide <span className="text-color-primary">truly prominent IT solutions.</span>
                  </h2>
                  Aamanto Technologies Inc. is the most trusted name in IT
                  consulting and software development services. We deliver
                  years of domain expertise and unrivaled value to our
                  clients. Our solutions power the operations of clients
                  across diverse industries such as finance, government,
                  transportation, retailing, telco and energy. We are a
                  one-stop solution for all of your IT needs, including mobile
                  app development, responsive web application development,
                  cloud adoption, cybersecurity, digital transformation and
                  data analytics.
                </div>
                {/* <!-- section-title-wrap Start --> */}
              </div>
            </div>

            {/* <!--===========  feature-icon-wrapper  Start =============--> */}
            <div className="feature-icon-wrapper section-space--ptb_100">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title-wrap text-center section-space--mb_40">
                      <h2 className="heading">Team up with us to take advantage of the following elements</h2>
                    </div>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-12">
                    <div className="feature-list__three">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="grid-item animate">
                            <div className="ht-box-icon style-03">
                              <div className="icon-box-wrap">
                                <div className="content-header">
                                  <div className="icon">
                                    <i className="fal fa-shield-check"></i>
                                  </div>
                                  <h5 className="heading">
                                    {" "}
                                    Advanced Technology
                                  </h5>
                                </div>
                                <div className="content">
                                  <div className="text">
                                    We at Aamanto are continuously up-to-date on
                                    the latest advances in the field of
                                    information technology. All new discoveries
                                    and learning are fed as practical benefits
                                    into our software as product features by our
                                    team of technology enthusiasts. This enables
                                    our clients to stand out, scale, grow and
                                    outperform their competitors.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="grid-item animate">
                            <div className="ht-box-icon style-03">
                              <div className="icon-box-wrap">
                                <div className="content-header">
                                  <div className="icon">
                                    <i className="fal fa-lock-alt"></i>
                                  </div>
                                  <h5 className="heading">
                                    Project Management
                                  </h5>
                                </div>
                                <div className="content">
                                  <div className="text">
                                    At Aamanto, we employ the best project
                                    management methodologies. This is due to our
                                    years’ experience, practical knowledge, and
                                    domain leadership. Our well-defined
                                    processes aid in the timely delivery of
                                    solutions that adhere to worldwide software
                                    development norms and satisfy our clients'
                                    demands.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="grid-item animate">
                            <div className="ht-box-icon style-03">
                              <div className="icon-box-wrap">
                                <div className="content-header">
                                  <div className="icon">
                                    <i className="fal fa-magnet"></i>
                                  </div>
                                  <h5 className="heading">Customer-Centric</h5>
                                </div>
                                <div className="content">
                                  <div className="text">
                                    All of Aamanto actions are guided by the
                                    objectives and expectations of our
                                    customers. In all we do, we strive for
                                    honesty and integrity. This is why every
                                    project begins with a forum to discuss
                                    expectations. We make every attempt to
                                    comprehend our clients' businesses, critical
                                    areas, and requirements. Interactions and
                                    feedback on a regular basis are an essential
                                    part of our approach to building world-class
                                    solutions. workloads.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="grid-item animate">
                            <div className="ht-box-icon style-03">
                              <div className="icon-box-wrap">
                                <div className="content-header">
                                  <div className="icon">
                                    <i className="fal fa-magnet"></i>
                                  </div>
                                  <h5 className="heading">Teamwork</h5>
                                </div>
                                <div className="content">
                                  <div className="text">
                                    We need the smartest candidates to produce
                                    the best results. Aamanto is a team of
                                    seasoned professionals with deep industry
                                    expertise and fresh ideas who specialize in
                                    CRM activities, web app, cloud computing,
                                    and product development. We can bring
                                    innovation to the table because we have the
                                    proper combination of experience and
                                    expertise from many sorts of areas. Our
                                    employees are trained on the newest
                                    advancements and technical abilities on a
                                    regular basis in order to evolve into
                                    competent professionals.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-6"
                          style={{ margin: "0px auto" }}
                        >
                          <div className="grid-item animate">
                            <div className="ht-box-icon style-03">
                              <div className="icon-box-wrap">
                                <div className="content-header">
                                  <div className="icon">
                                    <i className="fal fa-lock"></i>
                                  </div>
                                  <h5 className="heading"> Infrastructure</h5>
                                </div>
                                <div className="content">
                                  <div className="text">
                                    We provide the finest infrastructure to
                                    support our software offerings. This
                                    improves performance and guarantees that our
                                    client’s operations run smoothly.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div class="v1-box-2-parent">
                  <div class="v1-box-2-child">
                    <div className="v1-box-2-content">
                      <Link to="/">
                        <div className="v1-default-box-2">
                          <div className="row">
                            <div className="col-md-3 v1-img-2-1">
                              <img className="img-fluid" src={interactive} alt="" />
                            </div>
                            <div className="col-md-9">
                              <div className="v1-content-2">
                                <h5 className="v1-heading-2">Advanced Technology</h5>
                                <div className="v1-text-2">
                                  We at Aamanto are continuously up-to-date on the latest advances in the field of information technology. All new discoveries and learning are fed as practical benefits into our software as product features by our team of technology enthusiasts. This enables our clients to stand out, scale, grow and outperform their competitors.
                                </div>
                                <div className="v1-box-images-arrow-2">
                                  <span className="button-text">Read More</span>
                                  <i className="far fa-long-arrow-right"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div class="v1-box-2-child">
                    <div className="v1-box-2-content">
                      <Link to="/">
                        <div className="v1-default-box-2">
                          <div className="row">
                            <div className="col-md-3 v1-img-2-1">
                              <img className="img-fluid" src={management} alt="" />
                            </div>
                            <div className="col-md-9">
                              <div className="v1-content-2">
                                <h5 className="v1-heading-2">Project Management</h5>
                                <div className="v1-text-2">
                                  At Aamanto, we employ the best project management methodologies. This is due to our years’ experience, practical knowledge, and domain leadership. Our well-defined processes aid in the timely delivery of solutions that adhere to worldwide software development norms and satisfy our clients' demands.
                                </div>
                                <div className="v1-box-images-arrow-2">
                                  <span className="button-text">Read More</span>
                                  <i className="far fa-long-arrow-right"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div class="v1-box-2-child">
                    <div className="v1-box-2-content">
                      <Link to="/">
                        <div className="v1-default-box-2">
                          <div className="row">
                            <div className="col-md-3 v1-img-2-1">
                              <img className="img-fluid" src={customer} alt="" />
                            </div>
                            <div className="col-md-9">
                              <div className="v1-content-2">
                                <h5 className="v1-heading-2">Customer-Centric</h5>
                                <div className="v1-text-2">
                                  All of Aamanto actions are guided by the objectives and expectations of our customers. In all we do, we strive for honesty and integrity. This is why every project begins with a forum to discuss expectations. We make every attempt to comprehend our clients' businesses, critical areas, and requirements. Interactions and feedback on a regular basis are an essential part of our approach to building world-class solutions. workloads.
                                </div>
                                <div className="v1-box-images-arrow-2">
                                  <span className="button-text">Read More</span>
                                  <i className="far fa-long-arrow-right"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div class="v1-box-2-child">
                    <div className="v1-box-2-content">
                      <Link to="/">
                        <div className="v1-default-box-2">
                          <div className="row">
                            <div className="col-md-3 v1-img-2-1">
                              <img className="img-fluid" src={teamwork} alt="" />
                            </div>
                            <div className="col-md-9">
                              <div className="v1-content-2">
                                <h5 className="v1-heading-2">Teamwork</h5>
                                <div className="v1-text-2">
                                  We need the smartest candidates to produce the best results. Aamanto is a team of seasoned professionals with deep industry expertise and fresh ideas who specialize in CRM activities, web app, cloud computing, and product development. We can bring innovation to the table because we have the proper combination of experience and expertise from many sorts of areas. Our employees are trained on the newest advancements and technical abilities on a regular basis in order to evolve into competent professionals.
                                </div>
                                <div className="v1-box-images-arrow-2">
                                  <span className="button-text">Read More</span>
                                  <i className="far fa-long-arrow-right"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div class="v1-box-2-child">
                    <div className="v1-box-2-content">
                      <Link to="/">
                        <div className="v1-default-box-2">
                          <div className="row">
                            <div className="col-md-3 v1-img-2-1">
                              <img className="img-fluid" src={infrastructure} alt="" />
                            </div>
                            <div className="col-md-9">
                              <div className="v1-content-2">
                                <h5 className="v1-heading-2">Infrastructure</h5>
                                <div className="v1-text-2">
                                  We provide the finest infrastructure to support our software offerings. This improves performance and guarantees that our client’s operations run smoothly.
                                </div>
                                <div className="v1-box-images-arrow-2">
                                  <span className="button-text">Read More</span>
                                  <i className="far fa-long-arrow-right"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>

                </div>

              </div>

            </div>
            {/* <!--===========  feature-icon-wrapper  End =============-->

        {/* <!--===========  feature-large-images-wrapper  End =============--> */}
          </div>
        </div>
      </div >
    </div >
  )
}
export default WhyChooseUs
