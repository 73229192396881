import * as React from "react"
import Layout from "../components/layout"
import WhyChooseUs from "../components/Index/WhyChooseUs"






const WhyChooseUsPage = () => {
  return (
  <Layout>
   <WhyChooseUs />
  </Layout>
)
};
export default WhyChooseUsPage;
